import { render, staticRenderFns } from "./CometChatSenderFileMessageBubble.vue?vue&type=template&id=41804057&scoped=true&"
import script from "./CometChatSenderFileMessageBubble.vue?vue&type=script&lang=js&"
export * from "./CometChatSenderFileMessageBubble.vue?vue&type=script&lang=js&"
import style0 from "./CometChatSenderFileMessageBubble.vue?vue&type=style&index=0&id=41804057&scoped=true&lang=css&"
import style1 from "./CometChatSenderFileMessageBubble.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41804057",
  null
  
)

export default component.exports