var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cometchat__thread__wrapper",style:(_vm.styles.wrapper)},[_c('div',{style:(_vm.styles.header)},[_c('div',{style:(_vm.styles.headerWrapper)},[_c('div',{style:(_vm.styles.headerDetail)},[_c('h6',{style:(_vm.styles.headerTitle)},[_vm._v(_vm._s(_vm.STRINGS.THREAD))]),_c('span',{style:(_vm.styles.headerName)},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])]),_c('div',{style:(_vm.styles.headerClose),on:{"click":function($event){return _vm.emitAction('closeThreadClicked')}}})])]),_c('div',{style:(_vm.styles.messageContainer)},[_c('div',{style:(_vm.styles.parentMessage)},[(_vm.loggedInUser.uid === _vm.parentMessage.sender.uid)?[(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.TEXT &&
            _vm.parentMessage.text
          )?[_c('comet-chat-sender-text-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-text-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.IMAGE &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-sender-image-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-image-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.FILE &&
            _vm.parentMessage.data.attachments
          )?[_c('comet-chat-sender-file-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-file-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.VIDEO &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-sender-video-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-video-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.AUDIO &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-sender-audio-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-audio-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(_vm.parentMessage.type === _vm.ENUMS.CUSTOM_TYPE_POLL)?[_c('comet-chat-sender-poll-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-poll-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(_vm.parentMessage.type === _vm.ENUMS.CUSTOM_TYPE_STICKER)?[_c('comet-chat-sender-sticker-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-sender-sticker-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:_vm._e()]:[(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.TEXT &&
            _vm.parentMessage.text
          )?[_c('comet-chat-receiver-text-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-text-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.IMAGE &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-receiver-image-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-image-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.FILE &&
            _vm.parentMessage.data.attachments
          )?[_c('comet-chat-receiver-file-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-file-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.VIDEO &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-receiver-video-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-video-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(
            _vm.parentMessage.type === _vm.COMET_CHAT.MESSAGE_TYPE.AUDIO &&
            _vm.parentMessage.data.url
          )?[_c('comet-chat-receiver-audio-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-audio-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(_vm.parentMessage.type === _vm.ENUMS.CUSTOM_TYPE_POLL)?[_c('comet-chat-receiver-poll-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-poll-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:(_vm.parentMessage.type === _vm.ENUMS.CUSTOM_TYPE_STICKER)?[_c('comet-chat-receiver-sticker-message-bubble',_vm._b({attrs:{"message":_vm.parentMessage},on:{"action":_vm.actionHandler}},'comet-chat-receiver-sticker-message-bubble',_vm.senderRecieverMessageCommonProps,false))]:_vm._e()]],2),_c('div',{style:(_vm.styles.messageSeparator)},[(_vm.replyCount)?_c('span',{style:(_vm.styles.messageReply)},[_vm._v(" "+_vm._s(_vm.replyText)+" ")]):_vm._e(),_c('hr',{style:(_vm.styles.messageSeparator.hr)})]),_c('comet-chat-message-list',{attrs:{"item":_vm.item,"type":_vm.type,"theme":_vm.theme,"messages":_vm.messageList,"widgetconfig":_vm.widgetconfig,"logged-in-user":_vm.loggedInUser,"messageconfig":_vm.messageconfig,"scroll-to-bottom":_vm.scrollToBottom,"parent-message-id":_vm.parentMessage.id},on:{"action":_vm.actionHandler}}),_c('comet-chat-message-composer',{attrs:{"item":_vm.item,"type":_vm.type,"theme":_vm.theme,"reply-preview":_vm.replyPreview,"parent-message-id":_vm.parentMessage.id,"message-to-edit":_vm.messageToBeEdited},on:{"action":_vm.actionHandler}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }