<template>
  <svg width="26px" height="26px" viewBox="0 0 95 94" version="1.1"
       xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="arrow.up.left.and.arrow.down.right"
         transform="translate(-1402.000000, -1044.000000)" fill="#000000"
         fill-rule="nonzero">
        <g id="Symbols" transform="translate(501.000000, 622.000000)">
          <g id="Regular-M" transform="translate(901.860000, 422.000000)">
            <path
              d="M4.9434,39.666 C7.5312,39.666 9.3867,37.7129 9.3867,35.125 L9.3867,31.0234 L8.4102,13.9824 L21.252,27.5078 L36.291,42.6445 C37.1211,43.5234 38.1953,43.9141 39.3672,43.9141 C42.1504,43.9141 44.2012,42.1074 44.2012,39.373 C44.2012,38.0547 43.7617,36.8828 42.8828,36.0039 L27.7949,20.9648 L14.2695,8.123 L31.3594,9.0996 L35.4121,9.0996 C38,9.0996 40.002,7.293 40.002,4.6562 C40.002,2.0195 38.0488,0.1641 35.4121,0.1641 L8.3125,0.1641 C3.332,0.1641 0.4512,3.0449 0.4512,8.0254 L0.4512,35.125 C0.4512,37.6641 2.3555,39.666 4.9434,39.666 Z M58.5078,93.3281 L85.6074,93.3281 C90.588,93.3281 93.518,90.44727 93.518,85.4668 L93.518,58.3672 C93.518,55.8281 91.613,53.8262 88.977,53.8262 C86.4375,53.8262 84.5332,55.7793 84.5332,58.3672 L84.5332,62.4688 L85.5586,79.50977 L72.668,65.9844 L57.6777,50.8477 C56.8477,49.9688 55.7246,49.5781 54.5527,49.5781 C51.8184,49.5781 49.7188,51.3848 49.7188,54.1191 C49.7188,55.4375 50.207,56.6094 51.0859,57.4883 L66.125,72.52734 L79.6992,85.36914 L62.6094,84.39258 L58.5078,84.39258 C55.9199,84.39258 53.918,86.19922 53.918,88.83594 C53.918,91.47266 55.9199,93.3281 58.5078,93.3281 Z"
              id="Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FullscreenIcon',
};
</script>
