import { render, staticRenderFns } from "./CometChatGroupList.vue?vue&type=template&id=6204d553&scoped=true&"
import script from "./CometChatGroupList.vue?vue&type=script&lang=js&"
export * from "./CometChatGroupList.vue?vue&type=script&lang=js&"
import style0 from "./CometChatGroupList.vue?vue&type=style&index=0&id=6204d553&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6204d553",
  null
  
)

export default component.exports